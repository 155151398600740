<template>
  <div class="page">
    <div class="twins-page">
      <!-- 推荐人 -->
      <div class="header">
        <div class="header-item">
          <div class="force-name">{{ Invitee }}</div>
          <div class="force-num">{{ myInvitee }}</div>
        </div>
      </div>
      <Invitation></Invitation>
      <!-- 联系官方渠道 -->
      <!-- <div class="contract-list">
        <div
          class="contract-item"
          :id="'id' + item.id"
          v-for="item in contractList"
          :key="item.id"
          @click="handlIconClick(item)"
        >
          <svg class="contract-icon" aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { queryGetInvitee } from '@/services/twins'
import Clipboard from 'clipboard'
import Invitation from './Invitation.vue'

export default {
  components: { Invitation },
  data () {
    return {
      contractList: [
        // {
        //   id: 0,
        //   title: 'medium',
        //   icon: '#icon-medium',
        //   type: 1,
        //   url: 'https://medium.com/@Meblox'
        // },
        {
          id: 2,
          title: 'telegram',
          icon: '#icon-telegram',
          type: 1,
          url: 'https://t.me/MebloxProtocolOfficialChat'
        }, {
          id: 1,
          title: 'twitter',
          icon: '#icon-twitter',
          type: 1,
          url: 'https://twitter.com/MebloxProtocol'
        },
        // {
        //   id: 3,
        //   title: 'discord',
        //   icon: '#icon-discord',
        //   type: 1,
        //   url: 'https://discord.gg/Danv8VKM'
        // },
        // {
        //   id: 4,
        //   title: 'facebook',
        //   icon: '#icon-facebook',
        //   type: 1,
        //   url: 'https://www.facebook.com/profile.php?id=100071973141134'
        // },
        {
          id: 5,
          title: 'email',
          icon: '#icon-email',
          type: 2,
          url: 'mebloxprotocol@gmail.com'
        }
      ],
      myInvitee: '0x000...00000' // 我的上级
    }
  },
  computed: {
    myFriend () {
      return this.$t('twins.myFriend')
    },
    inviteFriends () {
      return this.$t('twins.inviteFriends')
    },
    copy () {
      return this.$t('twins.copy')
    },
    Invitee () {
      return this.$t('twins.Invitee')
    }
  },
  methods: {
    handleToPage (type) {
      if (type === 1) {
        this.$router.push({ path: '/myFriends', query: {} })
      } else {
        this.$router.push({ path: '/Invitation', query: {} })
      }
    },
    // 联系图标点击
    handlIconClick (item) {
      if (item.type === 1) {
        window.open(`${item.url}`)
      } else {
        const id = `#id${item.id}`
        const clipboard = new Clipboard(id, {
          text: () => {
            return item.url
          }
        })
        clipboard.on('success', () => {
          return this.$toast(this.copy)
        })
        clipboard.on('error', () => {
          return this.$toast(this.copy)
        })
      }
    },
    // 获取
    async getInvitee () {
      const resp = await queryGetInvitee()
      if (!resp.success) return false
      if (resp.message) this.myInvitee = this.$gbUtils.formatAddress(resp.message)
    }
  },
  mounted () {
    this.getInvitee()
  }
}
</script>

<style lang="scss" scoped>
.page {
  // min-height: 100vh;
  background: #f8f8f8;
  padding: 20px 13px 50px 13px;
  box-sizing: border-box;
}
.tab-page {
  padding-bottom: 30px !important;
}
.twins-page {
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  margin: 0 auto;
  min-height: 70%;
  .header {
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 10px 20px 5px 20px;
    line-height: 1.5em;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .header-item {
      height: 38px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 10px;
      box-sizing: border-box;
    }
    .header-item:last-child {
      border: none;
    }
    .force-name {
      font-size: 13px;
      font-weight: bold;
      color: #16172a;
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        padding-bottom: 2px;
      }
    }
    .force-num {
      font-size: 13px;
      color: #16172a;
    }
  }
  .blank {
    height: 15px;
  }
  .data-group {
    width: 100%;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .btn-group {
      width: 100%;
      margin: 0 auto;
      display: flex;
      line-height: 50px;
      color: #fff;
      justify-content: space-between;
      font-size: 12px;
    }
    .invite-btn {
      width: 48%;
      height: 40px;
      background-color: #24c294;
      border-radius: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #2a2b33;
      font-weight: bold;
      // margin-left: 5%;
      .friend-icon {
        width: 15px;
        height: 15px;
        display: inline;
        margin-right: 8px;
      }
    }
    .friend-btn {
      width: 48%;
      height: 50px;
      height: 40px;
      background: #16172a;
      font-weight: bold;
      opacity: 1;
      border-radius: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .friend-icon {
        width: 15px;
        height: 20px;
        display: inline;
        margin-right: 8px;
      }
    }
  }
  .contract-list {
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-evenly;
    margin: 25px 0;
    .contract-item {
      width: 25px;
      height: 25px;
      cursor: pointer;
      .contract-icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
